/** @jsx jsx */
import { jsx } from 'theme-ui'
import MainLayout from 'components/layouts/main'
import SEO from 'components/seo'
import PatientSearch from 'components/patientSearch'

const DashboardPage = () => (
  <MainLayout>
    <SEO title="Dashboard" />
    <PatientSearch />
  </MainLayout>
)

export default DashboardPage
