/** @jsx jsx */
import { jsx, Box, Themed } from 'theme-ui'
import { SearchContainerStyles, SearchTitleStyles } from './styles'
import PatientSearchForm from './form'
import { ThemedPaper } from '../paper'

const PatientSearch = () => {
  return (
    <ThemedPaper sx={SearchContainerStyles}>
      <Themed.h3 sx={SearchTitleStyles}>Patient Search</Themed.h3>
      <Themed.p>
        Search patients by name to check the status of their claim
      </Themed.p>
      <PatientSearchForm />
    </ThemedPaper>
  )
}

export default PatientSearch
