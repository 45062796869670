import { ThemeUICSSProperties } from 'theme-ui'
export * from './form'

export const SearchContainerStyles: ThemeUICSSProperties = {
  maxWidth: '555px',
  m: '-50px auto 0',
  textAlign: 'center',
  px: '47px',
  pt: '59px',
  pb: '65px',
}

export const SearchFormOrLabelStyles: ThemeUICSSProperties = {
  fontWeight: 'bold',
  mt: '45px',
  mb: '20px',
}

export const SearchTitleStyles: ThemeUICSSProperties = {
  fontFamily: 'body',
  fontSize: [4, 4],
  fontWeight: 'bold',
  color: 'text',
  mb: '16px',
}
