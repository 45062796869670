import { ThemeUICSSProperties } from 'theme-ui'

export const SearchFormStyles = {
  width: '328px',
  m: '18px auto 0',

  '.MuiTextField-root + .MuiTextField-root': {
    mt: '16px',
  },
}

export const SearchFormSubmitButtonStyles: ThemeUICSSProperties = {
  py: '13px',
  px: '82px',
  fontWeight: 'dark',
  mt: '37px',
}
