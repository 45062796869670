/** @jsx jsx */
import { useState, useEffect, useMemo } from 'react'
import { jsx, Themed } from 'theme-ui'
import { CustomTextField } from '../../common'
import { SearchFormStyles, SearchFormSubmitButtonStyles } from '../styles'
import { navigate } from 'gatsby'

const PatientSearchForm = () => {
  const initialInputs: Record<string, string> = {
    patient_first_name: '',
    patient_last_name: '',
  }

  const [inputs, setInputs] = useState(initialInputs)

  const [errors, setErrors] = useState(initialInputs)

  // Check if form has any errors
  const isValid = useMemo(() => {
    const err = Object.keys(errors).reduce(
      (a: string, k) => a + errors[k].trim(),
      ''
    )
    return err.length === 0 ? true : false
  }, [errors])

  const handleInputChange = (e: any) => {
    e.persist()
    setInputs(val => ({ ...val, [e.target.name]: e.target.value }))
  }

  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()

    // Clean fields before submitting to API
    Object.keys(inputs).map(k => (inputs[k] = inputs[k].trim()))

    navigate('/dashboard/patients/', { state: { inputs } })
  }

  // Validation
  const handleBlur = () => {
    if (inputs.patient_last_name.trim().length === 0) {
      setErrors(e => ({
        ...e,
        lastname: 'Field is required',
      }))
    } else {
      setErrors(e => ({ ...e, lastname: '' }))
    }
  }

  return (
    <form sx={SearchFormStyles} onSubmit={handleFormSubmit}>
      <CustomTextField
        fullWidth={true}
        label="First Name"
        value={inputs.patient_first_name}
        onChange={handleInputChange}
        name="patient_first_name"
      />
      <CustomTextField
        error={errors.patient_last_name.length ? true : false}
        required={true}
        helperText={errors.patient_last_name}
        fullWidth={true}
        label="Last Name"
        value={inputs.patient_last_name}
        onChange={handleInputChange}
        onBlur={handleBlur}
        name="patient_last_name"
      />
      <button
        type="submit"
        sx={{
          variant: isValid ? 'buttons.primary' : 'buttons.disabled',
          ...SearchFormSubmitButtonStyles,
        }}
        disabled={!isValid}
      >
        Search
      </button>
    </form>
  )
}

export default PatientSearchForm
